import { createApp } from 'vue'
import App from './App.vue'

import router from './router/router'
import store from '@/store/store.js'
import Toaster from "@meforma/vue-toaster"
//import VueGtag from 'vue-gtag'

import '@/assets/css/common.css'

import 'vue-fullpage.js/dist/style.css'
import VueFullPage from 'vue-fullpage.js'

const app = createApp(App)
app.use(router);
app.use(store);
app.use(VueFullPage)
app.use(Toaster, {
    // One of the options
    position: "bottom", duration: 3000, type: 'default', useDefaultCss: true, maxToasts: 1,
});
app.mount('#app');
