import { createStore } from 'vuex';

const store = createStore({
    state(){
        return {
            isMobile: false,
            isIphone: false,
        }
    },
    mutations:{
        setIsMobile(state, payload){
            state.isMobile = payload;
        },
        setIsIphone(state, payload){
            state.isIphone = payload;
        },
    },
});

export default store;