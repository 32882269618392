import { createRouter, createWebHistory } from 'vue-router'
const routes = [
    {
        path: '/',
        component: () => import('@/components/Home.vue'),
    },
    {
        path: '/consult',
        component: () => import('@/components/Consult.vue'),
    },
    {
        path: '/adminhome',
        component: () => import('@/components/AdminHome.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior (to, from, savedPosition) {
        // return desired position
        setTimeout(function(){
            if (savedPosition) {
                window.scrollTo({top:savedPosition.top, left:0, behavior:'smooth'}); 
            } else {
                window.scrollTo(0, 0);
            }
        }, 500);
      }
})

export default router